import defaultTheme from '@chakra-ui/theme';
import get from 'lodash/get';
import merge from 'lodash/merge';

import breakpoints from './breakpoints';

/*
chakra default breakpoints
const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
})
*/

const sans = '"Noto Sans TC", sans-serif';
const serif = '"Noto Serif TC", serif';
const round = 'jf-openhuninn, sans-serif';
const kai = 'edukai, 標楷體, sans-serif';
const fonts = {
  body: sans,
  heading: 'inherit',
  sans,
  serif,
  round,
  kai
};

const isBlackTheme = (props) =>
  props.colorScheme === 'blackAlpha' ||
  (props.colorScheme === 'quiz' &&
    !props.theme.colors.quiz?.[500]?.startsWith('#'));

const customTheme = {
  fonts,
  breakpoints,
  radii: {
    '2xl': '28px',
    '3xl': '32px',
    '4xl': '36px'
  },
  colors: {
    gray: {
      100: '#F9F8F6',
      200: '#EAE8E6',
      500: '#92918F',
      800: '#161616',
      900: '#020202'
    },
    accent: {
      mango: '#FFC23C',
      dodger: '#2089FF',
      orangered: '#FF6026',
      aqua: '#52D5AE'
    },
    promotion: {
      background: '#FFE9B3'
    },
    notice: {
      background: '#DBF1E7'
    },
    quiz: defaultTheme.colors.blackAlpha,
    // skin: '#FCF6ED',
    bgYellow: '#FCC65A',
    lightYellow: '#FCF6ED',
    bgGreen: '#78D4B0',
    textColor: '#696969',
    defaultBg: '#F9F8F6'
  },
  shadows: {
    sm: 'inset 0px -1px 0px rgba(0, 0, 0, 0.25)',
    outline: '0 0 6px 0px rgba(0, 0, 0, 0.6)'
  },
  sizes: {
    header: {
      compact: '48px',
      base: '96px',
      full: '152px'
    },
    container: {
      xs: '480px',
      '2xl': '1440px',
      '3xl': '1680px',
      '4xl': '1920px'
    }
  },
  components: {
    Button: {
      baseStyle: (props) => {
        return {
          borderRadius:
            (props.colorScheme === 'quiz' && props.theme.buttonRounded) ||
            'full',
          fontWeight: 'normal'
        };
      },
      variants: {
        solid: (props) => {
          if (isBlackTheme(props)) {
            return {
              bg: 'black',
              _hover: {
                bg: 'blackAlpha.800'
              }
            };
          } else {
            if (
              props.colorScheme === 'quiz' &&
              typeof props.theme.buttonIsFill === 'boolean' &&
              !props.theme.buttonIsFill
            ) {
              return defaultTheme.components.Button.variants.outline(props);
            }
          }
        },
        outline: (props) => {
          if (isBlackTheme(props)) {
            return {
              color: 'gray.800',
              borderColor: 'gray.500',
              border: '1px solid',
              bg: 'whiteAlpha.400',
              _hover: {
                bg: 'whiteAlpha.500'
              },
              _focus: {
                borderColor: 'gray.800',
                boxShadow: 'none'
              },
              _active: {
                bg: 'whiteAlpha.200'
              }
            };
          }
        },
        ghost: (props) => {
          if (isBlackTheme(props)) {
            return {
              color: 'black'
            };
          }
        },
        pure: {
          p: '0',
          width: 'auto',
          minW: 'auto',
          height: 'auto',
          bg: 'transparent',
          _hover: {},
          _active: {}
        }
      },
      defaultProps: {
        colorScheme: 'blackAlpha'
      },
      sizes: {
        md: {
          px: 6
        },
        xl: {
          h: 14,
          minW: 14,
          fontSize: 'xl',
          px: 6
        },
        '2xl': {
          h: 16,
          minW: 16,
          fontSize: '2xl',
          px: 8
        }
      }
    },
    Container: {
      sizes: {
        xl: {
          maxW: 'container.xl'
        },
        lg: {
          maxW: 'container.lg'
        },
        md: {
          maxW: 'container.md'
        },
        sm: {
          maxW: 'container.sm'
        },
        xs: {
          maxW: 'container.xs'
        }
      },
      defaultProps: {
        size: 'sm'
      }
    },
    Input: {
      variants: {
        outline: {
          field: {
            borderColor: 'gray.500',
            _hover: {
              borderColor: 'gray.800'
            }
          }
        },
        flushed: {
          field: {
            borderBottomColor: 'transparent',
            color: 'gray.900',
            _focus: {
              boxShadow: `none`,
              borderBottomColor: 'currentColor'
            }
          }
        }
      },
      defaultProps: {
        focusBorderColor: 'gray.900'
      },
      baseStyle: {
        field: {
          _placeholder: {
            color: 'gray.900',
            opacity: 0.4
          }
        }
      }
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: 'gray.900'
      },
      variants: {
        outline: {
          borderColor: 'gray.500',
          _hover: {
            borderColor: 'gray.800'
          }
        }
      },
      baseStyle: {
        borderBottom: 'none',
        color: 'gray.900',
        _focus: {
          boxShadow: `none`
        },
        _placeholder: {
          color: 'gray.900',
          opacity: 0.4
        }
      }
    },
    PinInput: {
      baseStyle: {
        fontWeight: 'black'
      },
      variants: {
        outline: {
          borderColor: 'gray.500',
          _focus: {
            borderColor: 'gray.800',
            boxShadow: '0 0 0 1px var(--chakra-colors-gray-800)'
          }
        }
      }
    },
    Select: {
      variants: {
        outline: {
          field: {
            borderColor: 'gray.500',
            _hover: {
              borderColor: 'gray.800'
            }
          }
        }
      },
      defaultProps: {
        focusBorderColor: 'gray.900'
      }
    },
    Modal: {
      sizes: {
        '2xs': {
          dialog: {
            maxW: '2xs'
          }
        },
        '7xl': {
          dialog: {
            maxW: '7xl'
          }
        },
        '8xl': {
          dialog: {
            maxW: '8xl'
          }
        },
        '9xl': {
          dialog: {
            maxW: '1680px'
          }
        },
        '10xl': {
          dialog: {
            maxW: '1920px'
          }
        }
      }
    },
    Switch: {
      baseStyle: (props) => {
        const base = {
          container: {
            px: 2
          },
          track: {
            bg: 'gray.200',
            border: '1px solid',
            borderColor: 'gray.500',
            pos: 'relative',
            width: 8,
            p: 0,
            _checked: {
              bg: 'gray.200'
            },
            _focus: {
              boxShadow: 'md'
            },
            height: 3
          },
          thumb: {
            bg: 'gray.100',
            border: '1px solid',
            borderColor: 'gray.800',
            width: 6,
            height: 6,
            pos: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translate(-50%, -50%)',
            boxShadow: 'md',
            _checked: {
              bg: 'gray.800',
              transform: 'translate(calc(var(--chakra-sizes-8) - 50%), -50%)'
            }
          }
        };
        if (isBlackTheme(props)) {
          return merge({}, base, {
            thumb: {
              bg: 'gray.100',
              borderColor: 'gray.800',
              _checked: {
                bg: 'gray.800'
              }
            }
          });
        }
        return base;
      },
      defaultProps: {
        colorScheme: 'blackAlpha'
      }
    },
    Editable: {
      baseStyle: {
        input: {
          _placeholder: {
            color: 'gray.900',
            opacity: 0.4
          }
        }
      },
      variants: {
        h1: {
          input: {
            fontSize: '24px',
            fontWeight: 'black'
          },
          preview: {
            fontSize: '24px',
            fontWeight: 'black'
          }
        },
        h2: {
          input: {
            fontSize: '18px',
            fontWeight: 'bold'
          },
          preview: {
            fontSize: '18px',
            fontWeight: 'bold'
          }
        }
      }
    },
    Divider: {
      baseStyle: {
        borderColor: 'gray.500'
      }
    },
    Tag: {
      variants: {
        outline: (props) => {
          if ((props.colorScheme = 'black')) {
            const borderColor = get(props.theme, `colors.gray.800`);
            return {
              container: {
                boxShadow: `inset 0 0 0px 1px ${borderColor}`
              },
              label: {
                color: 'gray.800'
              }
            };
          }
        },
        moduleName: {
          container: {
            backgroundColor: 'gray.200'
          },
          label: {
            color: 'gray.800'
          }
        }
      },
      sizes: {
        md: {
          container: {
            borderRadius: 'full'
          }
        }
      },
      baseStyle: {
        container: {
          lineHeight: '2'
        },
        label: {
          lineHeight: '2'
        }
      },
      defaultProps: {
        colorScheme: 'black',
        variant: 'outline'
      }
    },
    Radio: {
      baseStyle: (props) => {
        const isDefaultScheme = props.colorScheme === 'blackAlpha';
        if (isDefaultScheme) {
          return {
            control: {
              _checked: {
                bg: 'blackAlpha.900'
              }
            }
          };
        }
      },
      defaultProps: {
        colorScheme: 'blackAlpha'
      }
    },
    Checkbox: {
      baseStyle: (props) => {
        const isDefaultScheme = props.colorScheme === 'blackAlpha';
        if (isDefaultScheme) {
          return {
            control: {
              _checked: {
                bg: 'blackAlpha.900'
              }
            }
          };
        }
      }
    },
    Progress: {
      sizes: {
        xl: {
          track: { h: '1.375rem' }
        },
        '2xl': {
          track: { h: '1.75rem' }
        }
      },
      variants: {
        outline: {
          track: {
            border: '1px solid'
          }
        }
      }
    }
  },
  styles: {
    global: {
      body: {
        bg: 'defaultBg'
      }
    }
  }
};

export default customTheme;
