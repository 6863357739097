import React from 'react'
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import { TinyColor } from '@ctrl/tinycolor';

import defaultTheme from "./theme"
import getNextImageUrl from 'helpers/getNextImageUrl';

const getTheme = customTheme => {
  if (!customTheme) return {}
  const body = {}

  if (customTheme.backgroundColor) {
    body.bgColor = customTheme.backgroundColor
  }
  if (customTheme.backgroundImage?.src) {
    body.bgImage = `url(${getNextImageUrl(customTheme.backgroundImage.src)})`
    body.bgSize = 'cover'
    body.bgPosition = 'center center'
  }
  // if (customTheme.fontFamily) {
  //   body.fontFamily = customTheme.fontFamily
  // }
  let colors

  if (customTheme.primaryColor) {
    const baseColor = new TinyColor(customTheme.primaryColor)
    colors = {
      quiz: {
        200: baseColor.lighten(30).toString(),
        300: baseColor.lighten(20).toString(),
        400: baseColor.lighten(10).toString(),
        500: customTheme.primaryColor,
        600: baseColor.darken(10).toString(),
        700: baseColor.darken(20).toString(),
      }
    }
  }
  return {
    styles: {
      global: {
        body,
      },
    },
    colors,
    buttonIsFill: customTheme.buttonIsFill,
    buttonRounded: customTheme.buttonRounded,
    containerWidth: customTheme.containerWidth,
  }
}

const Provider = ({ children, theme }) => {
  return (
    <ChakraProvider
      resetCSS
      theme={extendTheme(defaultTheme, getTheme(theme))}
    >
      {children}
    </ChakraProvider>
  )
}

export default Provider
