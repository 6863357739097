import React, { useState } from 'react';
import { StateMachineProvider, createStore } from 'little-state-machine';
import dynamic from 'next/dynamic';
import PlausibleProvider from 'next-plausible';

// import { MediaContextProvider } from './responsive';
import { STATE_MACHINE } from '../constants/storageKeys';
import ContactProvider from './contact/provider';
import bgAudioContext from './bgAudio';

const FirebaseProvider = dynamic(() => import('./firebase/provider'));

createStore(
  {},
  {
    name: STATE_MACHINE
  }
);

const withFirebase = ['/login', '/logout', '/register', '/modules', '/terms'];

const Providers = ({ children, pathname, authedRoutes }) => {
  const [playing, setPlaying] = useState(true);

  const allProviders = (
    <PlausibleProvider
      domain={process.env.NEXT_PUBLIC_PLAUSIBLE_ID}
      customDomain={process.env.NEXT_PUBLIC_PLAUSIBLE_URL}
      selfHosted
      enabled
      trackLocalhost
    >
      {/* <MediaContextProvider> */}
      <StateMachineProvider>
        <ContactProvider>
          <bgAudioContext.Provider
            value={{
              playing,
              setPlaying
            }}
          >
            {children}
          </bgAudioContext.Provider>
        </ContactProvider>
      </StateMachineProvider>
      {/* </MediaContextProvider> */}
    </PlausibleProvider>
  );
  return withFirebase
    .concat(authedRoutes)
    .some((path) => pathname.includes(path)) ? (
    <FirebaseProvider>{allProviders}</FirebaseProvider>
  ) : (
    allProviders
  );
};

export default Providers;
