import hmacSHA256 from 'crypto-js/hmac-sha256';
import base64Url from 'crypto-js/enc-base64url';

const host = process.env.NEXT_PUBLIC_IMGPROXY_HOST;
const key = process.env.NEXT_PUBLIC_IMGPROXY_KEY;
const salt = process.env.NEXT_PUBLIC_IMGPROXY_SALT;

const urlSafeBase64 = (str) =>
  Buffer.from(str)
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');

const getSignature = (url) => {
  const saltedUrl = `${salt}/${url}`;
  const signed = hmacSHA256(saltedUrl, key).toString(base64Url);
  return signed;
};

export default function imgProxyLoader({ src, width, quality }) {
  let s = src;
  if (!src) return '';
  if (src.startsWith(host)) return src;
  if (src.startsWith('/')) {
    if (process.env.NODE_ENV === 'production') {
      const vercelEnv = process.env.NEXT_PUBLIC_VERCEL_ENV;
      const vercelUrl = process.env.NEXT_PUBLIC_VERCEL_URL;
      const publicURL = process.env.NEXT_PUBLIC_URL;
      s = `https://${
        vercelEnv === 'production' && publicURL ? publicURL : vercelUrl
      }${src}`;
    } else {
      return src;
    }
  }
  const configs = `rs:fill:${width}/q:${quality || 75}`;
  s = urlSafeBase64(s);

  return [
    host,
    getSignature(`${configs}/${s}`),
    // 'insecure',
    configs,
    s
  ].join('/');
}
