import React from 'react';
import { forwardRef, Box } from '@chakra-ui/react';

const SVG = forwardRef(({
  viewBox,
  children,
  ...props
}, ref) => {
  return (
    <Box
      as="svg"
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      {...props}
    >
      {children}
    </Box>
  )
})

SVG.defaultProps = {
  display: 'inline-block',
}

SVG.displayName = 'SVG';

export default SVG;
