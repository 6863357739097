import { Box, Text } from '@chakra-ui/react';
import React from 'react';
// import * as Sentry from "@sentry/nextjs";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
    // Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) return this.props.fallback;
      // You can render any custom fallback UI
      return (
        <Box
          py="3em"
          textAlign="center"
          border="2px solid"
          borderColor="red.300"
          rounded="lg"
        >
          {this.props.withDetail ? (
            <pre>{this.props.error?.message}</pre>
          ) : (
            <Text fontSize="md">
              抱歉，出現了一點問題
              <br />
              我們的技術團隊正在修復中
            </Text>
          )}
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
