import { useMemo } from 'react';

const ensureSlash = (pathname) => {
  if (!pathname) return '';
  return pathname.startsWith('/') ? pathname : `/${pathname}`;
};

const useSiteLink = (pathname) =>
  useMemo(() => {
    if (!pathname) return '---';
    return process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined'
      ? `${location.origin}${ensureSlash(pathname)}`
      : `https://${
          process.env.NEXT_PUBLIC_URL ?? process.env.NEXT_PUBLIC_VERCEL_URL
        }${ensureSlash(pathname)}`;
  }, [pathname]);

export default useSiteLink;
