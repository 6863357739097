import { useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'

import context from './context'
import dynamic from 'next/dynamic'

const Modal = dynamic(() => import('components/Modal'))
const ContactForm = dynamic(() => import('./ContactForm'))

const ContactProvider = ({ children }) => {
  const [defaultType, setDefaultType] = useState()
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <context.Provider
      value={{
        openForm: (type) => {
          setDefaultType(type)
          onOpen()
        }
      }}
    >
      {children}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        withCloseButton
      >
        <ContactForm defaultType={defaultType} />
      </Modal>
    </context.Provider>
  )
}

export default ContactProvider
