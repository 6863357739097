import imgProxyLoader from 'imgproxy';
const deviceSizes = [640, 750, 828, 1080, 1200, 1920, 2048].reverse();

const isProd = process.env.NODE_ENV === 'production';

const getNextImageUrl = (image, max = 640, q = 90) => {
  let url;
  if (image) {
    if (typeof image === 'string') {
      url = image;
    }

    if (typeof image === 'object') {
      url = image.src;
    }
    if (!url) return '';
    if (url.startsWith('/_next/image')) return url;
    let w = max || deviceSizes[deviceSizes.length - 1];
    if (typeof window !== 'undefined') {
      const found = deviceSizes.find(
        (size) => Math.min(max, window.innerWidth) >= size
      );
      if (found) {
        w = found;
      }
    }
    if (isProd) {
      url = imgProxyLoader({
        src: url,
        width: w,
        quality: q
      });
    } else {
      url = `/_next/image?url=${encodeURIComponent(url)}&w=${w || 640}&q=${q}`;
    }
  }
  return url;
};

export default getNextImageUrl;
