import {
  Box,
  Icon,
  IconButton,
  // Popover,
  // PopoverArrow,
  // PopoverBody,
  // PopoverContent,
  // PopoverTrigger,
  useDisclosure
} from '@chakra-ui/react';
import { BG_SOUND_NOTIFIED } from 'constants/storageKeys';
import bgAudioContext from 'contexts/bgAudio';
import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactHowler from 'react-howler';
// import { HiSpeakerWave, HiSpeakerXMark } from 'react-icons/hi2';
import { usePrevious, useSessionStorage } from 'react-use';
import VoiceClosedIcon from './VoiceClosedIcon';
import VoiceIcon from './VoiceIcon';

const rules = {
  // cover: /^\/(preview|quiz)\/\[quizId\]$/i,
  question: /^\/(preview|quiz)\/\[quizId\]\/question/i,
  result: /^\/(preview|quiz)\/\[quizId\]\/result/i
};

const BgAudioPlayer = ({ config, router }) => {
  const { pathname, query } = router;
  const howlerRef = useRef();
  const { playing, setPlaying } = useContext(bgAudioContext);
  const [isEnabled, setIsEnabled] = useState(false);
  const [hasNotified, setHasNotified] = useSessionStorage(
    BG_SOUND_NOTIFIED + query.quizId
  );
  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: !playing && !hasNotified,
    onClose: () => {
      if (window.top === window.self) {
        setHasNotified(true);
      }
    }
  });
  const prevEnabled = usePrevious(isEnabled);
  useEffect(() => {
    const curPath = Object.entries(rules).find(([, rule]) =>
      rule.test(pathname)
    );
    if (curPath) {
      const nowEnabled =
        curPath[0] === 'question' || (config?.playing?.[curPath[0]] ?? false);
      setIsEnabled(nowEnabled);
      if (nowEnabled && !prevEnabled) {
        howlerRef.current?.seek(0);
      }
    } else {
      setIsEnabled(false);
    }
  }, [pathname]);
  // useEffect(() => {
  //   if (isEnabled) {
  //     howlerRef.current?.seek(0)
  //   }
  // }, [isEnabled])
  if (!config?.src || config?.enabled === false) return null;
  return (
    <Box pos="fixed" top="1em" left="1em" zIndex="1">
      {isEnabled && (
        <>
          <IconButton
            height={'auto'}
            width={'auto'}
            minW="auto"
            p="0.5rem"
            _hover={{}}
            aria-label={playing ? '關閉' : '播放'}
            border={config?.showBorder && '2px solid'}
            bg={config?.bgColor || 'transparent'}
            color={config?.iconColor || 'black'}
            borderColor={config?.borderColor}
            isRound
            onClick={() => {
              setPlaying((v) => !v);
              onClose();
            }}
            icon={
              <Icon
                as={playing ? VoiceIcon : VoiceClosedIcon}
                w={config?.size || 6}
                h={config?.size || 6}
              />
            }
          />
          {isOpen && (
            <>
              <Box
                pos="absolute"
                left="50%"
                transform={'translate(-50%, 0.125rem)'}
                top="100%"
                borderLeft="5px solid transparent"
                borderRight="5px solid transparent"
                borderBottom={`8px solid ${config.bgColor || 'transparent'}`}
              />
              <Box
                pos="absolute"
                px="0.5rem"
                py="0.25rem"
                borderRadius={'5px'}
                width={config.helper.length * 14 + 16}
                bg={config.bgColor || 'transparent'}
                color={config.iconColor || 'black'}
                top="100%"
                left="0%"
                transform={'translateY(0.625rem)'}
                fontSize="sm"
                _before={{
                  content: "''"
                }}
              >
                {config.helper}
              </Box>
            </>
          )}
        </>
      )}

      <ReactHowler
        src={config.src}
        playing={isEnabled && playing}
        html5
        // mute={!isEnabled}
        ref={howlerRef}
        onEnd={() => {
          console.log('onEnd');
          howlerRef.current?.play();
        }}
        onPlayError={console.error}
      />
    </Box>
  );
};

export default BgAudioPlayer;
