export const KEY_PREFIX = 'SQOL'
export const STATE_MACHINE = `${KEY_PREFIX}:state-machine`
export const LOGGEDIN = `${KEY_PREFIX}:loggedIn`
export const FIREBASE_TOKEN = `${KEY_PREFIX}:firebaseToken`
export const HAS_READ_MODULE_TOGGLER = `${KEY_PREFIX}:hasReadModuleToggler`

export const CTA_FROM_SUBMITTED = `${KEY_PREFIX}:ctaFormSubmitted`

export const QUIZ_ANSWER_ID = `${KEY_PREFIX}:answerId`
export const UTM = `${KEY_PREFIX}:utm`
export const REF = `${KEY_PREFIX}:ref`

export const ADMIN_TABLE_STATE = `${KEY_PREFIX}:adminTableState`

export const FALLBACK_QUESTION = `${KEY_PREFIX}:fallbackQuestion`
export const BG_SOUND_NOTIFIED = `${KEY_PREFIX}:bgSoundNotified`
export const PREFILL_ANSWERS = `${KEY_PREFIX}:prefillAnswers`
export const FACTOR_FILTER = `${KEY_PREFIX}:factorFilter`
