import { useEffect } from 'react';
import Script from 'next/script';
import dynamic from 'next/dynamic';
import 'focus-visible/dist/focus-visible';
import Head from 'next/head';

import '../styles/globals.css';
import '../styles/@wordpress/block-library/build-style/style.css';
import * as gtag from '../helpers/gtag';
import Providers from '../contexts/providers';
import Provider from '../contexts/chakra-ui/Provider';
import { REF, UTM } from 'constants/storageKeys';
import BgAudioPlayer from 'components/BgAudioPlayer';
import ErrorBoundary from 'components/ErrorBoundary';
import useSiteLink from 'hooks/useSiteLink';
import 'animate.css';

const Footer = dynamic(() => import('../components/footer/Editor'));
const EnsureAuth = dynamic(() => import('../containers/EnsureAuth'));
const BottomNavigation = dynamic(() =>
  import('../components/navigation/Bottom')
);

const authedRoutes = ['/admin', '/create', '/editor', '/account', '/plans'];

const quizRoutes = ['/preview', '/quiz'];

const withBottomNavRoutes = ['/create', '/editor', '/plans', '/account'];
const withFooterRoutes = ['/login', '/register'];

function MyApp({ Component, pageProps, router }) {
  const loginRequired = authedRoutes.some((r) => router.pathname.startsWith(r));
  const cleanAsPath = router.asPath.replace(/\?.*$/, '');
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      window.gtag = window.gtag ?? console.log;
    }
    const params = new URLSearchParams(window.location.search);
    if (params.has('ref')) {
      const ref = params.get('ref');
      window.sessionStorage.setItem(REF, ref);
      if (typeof window.gtag === 'function') {
        window.gtag('set', 'campaign_id', ref);
      }
    }
    const utm = {};
    params.forEach((value, key) => {
      if (key.startsWith('utm_')) {
        utm[key.replace('utm_', '')] = value;
      }
    });
    if (Object.keys(utm).length > 0) {
      window.sessionStorage.setItem(UTM, JSON.stringify(utm));
    }
  }, []);
  // useEffect(() => {
  //   const handleRouteChange = (url) => {
  //     if (typeof window.gtag === 'function') {
  //       window.gtag('event', 'page_view', {
  //         // page_title: '<Page Title>',
  //         // page_location: '<Page Location>',
  //         page_path: url,
  //         send_to: gtag.GA_TRACKING_ID
  //       });
  //     }
  //   };
  //   router.events.on('routeChangeComplete', handleRouteChange);
  //   return () => {
  //     router.events.off('routeChangeComplete', handleRouteChange);
  //   };
  // }, [router.events]);
  const isPreview = router.pathname.startsWith('/preview');
  const isQuiz = quizRoutes.some((r) => router.pathname.startsWith(r));
  return (
    <Provider theme={isQuiz && pageProps?.theme}>
      <Providers
        authedRoutes={authedRoutes}
        pathname={router.pathname}
        pageProps={pageProps}
      >
        <Head>
          <title>{process.env.NEXT_PUBLIC_APP_NAME}</title>
          {process.env.NEXT_PUBLIC_GTM_ID && (
            <>
              <script
                dangerouslySetInnerHTML={{
                  __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`
                }}
              />
            </>
          )}
          <link
            rel="canonical"
            href={useSiteLink(cleanAsPath)}
            key="canonical"
          />
        </Head>
        <>
          {process.env.NEXT_PUBLIC_GTM_ID && (
            <noscript>
              <iframe
                src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
              ></iframe>
            </noscript>
          )}

          {gtag.GA_TRACKING_ID && (
            <>
              <Script
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
              />
              <Script
                id="gtag-init"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', '${gtag.GA_TRACKING_ID}');
                      ${
                        pageProps.tagManager?.ga && !isPreview
                          ? `gtag('config', '${pageProps.tagManager?.ga}');`
                          : ''
                      }
                    `
                }}
              />
            </>
          )}
          {pageProps?.tagManager?.gtm && !isPreview && (
            <>
              <Script
                id="gtm-init"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${pageProps.tagManager.gtm}');
                    `
                }}
              />
              <noscript>
                <iframe
                  src={`https://www.googletagmanager.com/ns.html?id=${pageProps.tagManager.gtm}`}
                  height="0"
                  width="0"
                  style={{ display: 'none', visibility: 'hidden' }}
                ></iframe>
              </noscript>
            </>
          )}
          {process.env.NEXT_PUBLIC_CLARITY_ID && (
            <Script
              id="clarity-init"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                    (function(c,l,a,r,i,t,y){
                      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_ID}");
                  `
              }}
            />
          )}
          {(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID ||
            pageProps?.tagManager?.pixel) && (
            <>
              <Script
                id="facebook-pixel"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `
                      !function(f,b,e,v,n,t,s)
                      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                      n.queue=[];t=b.createElement(e);t.async=!0;
                      t.src=v;s=b.getElementsByTagName(e)[0];
                      s.parentNode.insertBefore(t,s)}(window, document,'script',
                      'https://connect.facebook.net/en_US/fbevents.js');
                      ${
                        process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID
                          ? `fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}');`
                          : ''
                      }
                      ${
                        pageProps?.tagManager?.pixel && !isPreview
                          ? `
                      fbq('init', '${pageProps.tagManager.pixel}');
                      `
                          : ''
                      }
                      fbq('track', 'PageView');
                    `
                }}
              />
              <noscript
                dangerouslySetInnerHTML={{
                  __html: `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1"/>`
                }}
              />
            </>
          )}
        </>
        <ErrorBoundary withDetail>
          {loginRequired ? (
            <EnsureAuth router={router}>
              <Component {...pageProps} />
            </EnsureAuth>
          ) : (
            <Component {...pageProps} />
          )}
        </ErrorBoundary>
        {withBottomNavRoutes.some((r) => router.pathname.startsWith(r)) && (
          <BottomNavigation />
        )}
        {withFooterRoutes.some((r) => router.pathname.startsWith(r)) && (
          <Footer pageProps={pageProps} />
        )}
        {isQuiz && <BgAudioPlayer config={pageProps.music} router={router} />}
      </Providers>
    </Provider>
  );
}

export default MyApp;
