import React from 'react'
import SVG from './SVG'

const VoiceClosedIcon = (props) => {
  return (
    <SVG viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/SVG" {...props}>
      <path d="M12.8333 5.83333L6.99998 10.5H2.33331V17.5H6.99998L12.8333 22.1667V5.83333Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26.8333 10.5L19.8333 17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.8333 10.5L26.8333 17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </SVG>
  )
}

export default VoiceClosedIcon
