import React from 'react';
import SVG from './SVG';

const VoiceIcon = (props) => {
  return (
    <SVG
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/SVG"
      {...props}
    >
      <path
        d="M12.8333 5.83333L6.99998 10.5H2.33331V17.5H6.99998L12.8333 22.1667V5.83333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2483 5.75166C24.4355 7.93949 25.6642 10.9064 25.6642 14C25.6642 17.0936 24.4355 20.0605 22.2483 22.2483M18.13 9.87C19.2236 10.9639 19.8379 12.4474 19.8379 13.9942C19.8379 15.541 19.2236 17.0244 18.13 18.1183"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

export default VoiceIcon;
